import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import styles from "../constants/theme";
import Link from "./Link";
import { FaFacebookF, FaTwitter } from "react-icons/fa";

import Text from "./Text";

const Wrap = styled.div`
  background-color: ${props => props.theme.bg};
  /* min-height: 33.33vh; */
  margin-top: -100px;
  padding-top: 132px;
`;

export const SocialLink = styled(Link)`
  &,
  &:hover,
  &:focus {
    font-size: 1.4em;
    color: white;
    margin-right: ${styles.margin.small};
  }

  &:last-of-type {
    margin: 0;
  }
`;

export default function Footer() {
  return (
    <ThemeProvider theme={styles.palette.blue}>
      <Wrap>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <Text
                css={css`
                  font-size: ${styles.text.pFeat};
                `}
              >
                JOIN THE CONVERSATION
              </Text>
              <div
                css={css`
                  ${styles.flexCenter()};
                  margin-bottom: ${styles.margin.small};
                `}
              >
                <SocialLink to="http://twitter.com/theocma">
                  <FaTwitter />
                </SocialLink>
                <SocialLink to="https://www.facebook.com/theOCMA/">
                  <FaFacebookF />
                </SocialLink>
              </div>
              <Text>Website maintained by the independently organized, not-for-profit OCMA.</Text>
              <Text>
                Website powered by{" "}
                <Link
                  to="https://harrislearn.com/"
                  css={css`
                    &,
                    &:hover,
                    &:focus {
                      color: ${styles.colors.white};
                      border-bottom: 1px solid currentColor;
                    }
                  `}
                >
                  Harris Learning Solutions
                </Link>
                .
              </Text>
            </div>
          </div>
        </div>
        <div
          css={css`
            background-color: rgba(0, 0, 0, 0.2);
            padding: ${styles.margin.small} 0;
            text-align: center;
          `}
        >
          <Text
            css={css`
              margin: 0;
            `}
          >
            © {new Date().getFullYear()} OCMA
          </Text>
        </div>
      </Wrap>
    </ThemeProvider>
  );
}
